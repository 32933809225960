var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticClass:"fill-height"},[_c('v-container',[_c('v-toolbar',{staticClass:"d-print-none",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t(("$vuetify.routes." + (_vm.$route.name)))))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('filter-feilds',{attrs:{"label":_vm.$vuetify.lang.t("$vuetify.pages.reports.range"),"fieldModel":_vm.model.schema.start},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.filtersKey.length)?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":_vm.clearFilters}},[_c('v-icon',[_vm._v("mdi-filter-remove-outline")])],1):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-filter-outline ")])],1)]}}]),model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.filters")))])]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.fields.filter(function (a) { return a.value !== 'actions' && a.filterable === undefined
                        ? true
                        : a.filterable; }
                    )),function(field){return _c('v-col',{key:field.value,staticClass:"py-2",attrs:{"cols":(field.filterGrid && field.filterGrid.cols) ||
                        (field.inputGrid && field.inputGrid.cols) ||
                        12,"sm":(field.filterGrid && field.filterGrid.sm) ||
                        (field.inputGrid && field.inputGrid.sm),"md":(field.filterGrid && field.filterGrid.md) ||
                      (field.inputGrid && field.inputGrid.md) ||
                      field.type === Date
                        ? 12
                        : 6,"lg":(field.filterGrid && field.filterGrid.lg) ||
                        (field.inputGrid && field.inputGrid.lg),"xl":(field.filterGrid && field.filterGrid.xl) ||
                        (field.inputGrid && field.inputGrid.xl)}},[_c('filter-feilds',{attrs:{"label":field.text,"fieldModel":field,"default":field.default},model:{value:(_vm.filters[field.value]),callback:function ($$v) {_vm.$set(_vm.filters, field.value, $$v)},expression:"filters[field.value]"}})],1)}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.filterDialog = false}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cancel"))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.valid && _vm.loading,"color":"blue darken-1","text":"","type":"submit","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.apply"))+" ")])],1)],1)],1)],1)],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('pie-chart',{attrs:{"chart-data":_vm.scoreSumPie,"options":_vm.scoreSumPieOptions}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('line-chart',{attrs:{"chart-data":_vm.scoreSum,"options":{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  display: false
                }
              }
            }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('wordcloud',{attrs:{"data":_vm.wordcloudData,"rotate":{ from: 0, to: 0 },"spiral":"rectangular","font":"Vazir","showTooltip":false,"fontScale":"n","wordClick":_vm.wordClickHandler}})],1)],1),_vm._l((_vm.events),function(event){return [_c('event-card',{key:event._id,attrs:{"event":event,"isReport":""}})]})],_c('v-fab-transition',[(_vm.goToTopVisible)?_c('v-btn',{attrs:{"color":"primary","fixed":"","fab":"","dark":"","bottom":"","right":""},on:{"click":function($event){return _vm.$vuetify.goTo(0, { easing: 'easeInOutCubic' })}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }