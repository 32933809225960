<template>
  <div class="fill-height" v-scroll="onScroll">
    <v-container>
      <v-toolbar flat class="d-print-none">
        <v-toolbar-title>{{
          $vuetify.lang.t(`$vuetify.routes.${$route.name}`)
        }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <filter-feilds
          v-model="range"
          :label="$vuetify.lang.t(`$vuetify.pages.reports.range`)"
          :fieldModel="model.schema.start"
        />
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn v-if="filtersKey.length" icon color="red" @click="clearFilters">
          <v-icon>mdi-filter-remove-outline</v-icon>
        </v-btn>
        <v-dialog scrollable v-model="filterDialog" max-width="800px">
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon> mdi-filter-outline </v-icon>
            </v-btn>
          </template>
          <v-form v-model="valid" @submit.prevent="filter">
            <v-card>
              <v-card-title>
                <span class="headline">{{
                  $vuetify.lang.t(`$vuetify.filters`)
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row align="center">
                    <v-col
                      v-for="field in fields.filter(a =>
                        a.value !== 'actions' && a.filterable === undefined
                          ? true
                          : a.filterable
                      )"
                      :cols="
                        (field.filterGrid && field.filterGrid.cols) ||
                          (field.inputGrid && field.inputGrid.cols) ||
                          12
                      "
                      :sm="
                        (field.filterGrid && field.filterGrid.sm) ||
                          (field.inputGrid && field.inputGrid.sm)
                      "
                      :md="
                        (field.filterGrid && field.filterGrid.md) ||
                        (field.inputGrid && field.inputGrid.md) ||
                        field.type === Date
                          ? 12
                          : 6
                      "
                      :lg="
                        (field.filterGrid && field.filterGrid.lg) ||
                          (field.inputGrid && field.inputGrid.lg)
                      "
                      :xl="
                        (field.filterGrid && field.filterGrid.xl) ||
                          (field.inputGrid && field.inputGrid.xl)
                      "
                      :key="field.value"
                      class="py-2"
                    >
                      <filter-feilds
                        v-model="filters[field.value]"
                        :label="field.text"
                        :fieldModel="field"
                        :default="field.default"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey" text @click="filterDialog = false">
                  {{ $vuetify.lang.t(`$vuetify.cancel`) }}
                </v-btn>
                <v-btn
                  :disabled="!valid && loading"
                  color="blue darken-1"
                  text
                  type="submit"
                  :loading="loading"
                >
                  {{ $vuetify.lang.t(`$vuetify.apply`) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
      <v-progress-linear indeterminate v-if="loading" />
      <template v-else>
        <v-row>
          <v-col cols="12" md="6"
            ><pie-chart :chart-data="scoreSumPie" :options="scoreSumPieOptions"
          /></v-col>
          <v-col cols="12" md="6"
            ><line-chart
              :chart-data="scoreSum"
              :options="{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    display: false
                  }
                }
              }"
            />
          </v-col>
          <v-col cols="12">
            <wordcloud
              :data="wordcloudData"
              :rotate="{ from: 0, to: 0 }"
              spiral="rectangular"
              font="Vazir"
              :showTooltip="false"
              fontScale="n"
              :wordClick="wordClickHandler"
            />
          </v-col>
        </v-row>

        <template v-for="event in events">
          <event-card :key="event._id" :event="event" isReport />
        </template>
      </template>
      <v-fab-transition>
        <v-btn
          @click="$vuetify.goTo(0, { easing: 'easeInOutCubic' })"
          v-if="goToTopVisible"
          color="primary"
          fixed
          fab
          dark
          bottom
          right
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>
  </div>
</template>

<script>
import EventCard from "@/components/Calendar/eventCard";
import FilterFeilds from "@/components/DataTable/filterFields";
import { calendarService } from "@/services/calendar";
import model, { scoreColorSets } from "@/models/eventAndEventSource";
import { getValueOfObj, getValue, groupBy } from "@/tools/functions";
import { deepEqual } from "fast-equals";
import {
  format,
  differenceInCalendarDays,
  addDays
} from "@/tools/date-library";
import { persianNumber } from "@/plugins/filters";
import PieChart from "@/components/charts/Pie";
import LineChart from "@/components/charts/Line";
import "chartjs-plugin-datalabels";
import wordcloud from "@/components/WordCloud";

export default {
  data: function() {
    return {
      goToTopVisible: false,
      range: [new Date(), new Date(new Date().getTime() + 2.628e9)],
      events: [],
      valid: false,
      loading: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 15
      },
      filterDialog: false,
      page: 1,
      search: "",
      sortBy: undefined,
      sortDesc: undefined,
      filters: {},
      model
    };
  },
  components: {
    EventCard,
    FilterFeilds,
    PieChart,
    LineChart,
    wordcloud
  },
  methods: {
    wordClickHandler(name) {
      const event = this.events.find(event => event.title === name);
      this.$vuetify.goTo(`#event-${event._id}`, {
        offset: 40
      });
    },
    getTime(time) {
      return time.getTime ? time.getTime() : time;
    },
    pushQuery() {
      if (!deepEqual(this.filters, this.$route.query)) {
        this.$router
          .replace({ ...this.$route, query: this.filters })
          .catch(() => {});
      }
    },
    filter() {
      this.pushQuery();
      this.filterDialog = false;
    },
    clearFilters() {
      this.filters = {};
      this.pushQuery();
    },
    createQuery(filters, model) {
      return Reflect.ownKeys(filters)
        .filter(key => key !== "__ob__")
        .map(field => {
          if (filters[field] === undefined) return undefined;
          switch (model.schema[field].type) {
            case Date:
            case Number:
              return {
                field,
                operator: model.schema[field].filterOperator || "bt",
                value: Array.isArray(filters[field])
                  ? filters[field].map(a => +a)
                  : [+filters[field], null]
              };
            case Array:
              return {
                field,
                operator: model.schema[field].filterOperator || "eq",
                value: filters[field]
              };
            default:
              if (
                model.schema[field].multiple ||
                model.schema[field].multipleFilter
              ) {
                return {
                  field,
                  operator: model.schema[field].filterOperator || "eq",
                  value: filters[field]
                };
              }
              return {
                field,
                operator: model.schema[field].filterOperator || "eq",
                value: [filters[field]]
              };
          }
        })
        .filter(key => key);
    },
    getItems() {
      const start = this.range[0],
        end = this.range[1],
        filters = this.filters;
      this.loading = true;
      calendarService
        .getEvents(
          this.createQuery(filters, model),
          this.getTime(start),
          this.getTime(end)
        )
        .then(res => {
          this.events = res.data.items
            .map(event => ({
              ...event,
              start: new Date(event.start),
              end: new Date(event.end)
            }))
            .sort((a, b) => a.start - b.start);
          this.total = res.data.total;
          document.title = `سامانه جامع مدیریت رویداد | گزارش رویداهای ${this.dateRange}`;
        })
        .finally(() => (this.loading = false));
    },
    onScroll() {
      this.goToTopVisible = this.getScrollTop() > 200;
    },
    getScrollTop() {
      if (typeof pageYOffset != "undefined") {
        return pageYOffset;
      } else {
        const B = document.body; //IE 'quirks'
        let D = document.documentElement; //IE with doctype
        D = D.clientHeight ? D : B;
        return D.scrollTop;
      }
    }
  },
  computed: {
    calendarType: {
      get() {
        return this.$store.getters["global/getCalendarType"];
      },
      set(val) {
        this.$store.commit("global/setCalendarType", val);
      }
    },
    scoreSumPieOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 50,
        legend: false,
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 15,
              family: "Vazir"
            },
            color: "black",
            formatter: function(value, context) {
              if (!value) return;
              const total = context.dataset.data.reduce(
                (acc, item) => acc + item,
                0
              );
              if (context.dataIndex < 10) {
                return `${persianNumber(
                  Math.round((value * 100) / total)
                )}٪: ${persianNumber(Math.abs(context.dataIndex - 10))}-`;
              } else {
                return `${persianNumber(
                  Math.round((value * 100) / total)
                )}٪: ${persianNumber(context.dataIndex - 9)}`;
              }
            }
          }
        }
      };
    },
    days() {
      return new Array(
        differenceInCalendarDays[0](this.range[1], this.range[0]) + 1
      )
        .fill()
        .map((item, index) => addDays[0](this.range[0], index));
    },
    wordcloudData() {
      return this.events
        .filter(a => a.score)
        .map(event => ({
          name: event.title,
          color: event.color,
          value: Math.abs(event.score)
        }));
    },
    scoreSumPie() {
      const groupedEvents = groupBy(this.events, "score");
      const scores = new Array(21)
        .fill()
        .map((item, index) => index - 10)
        .filter(a => a);
      return {
        labels: scores.map(item => persianNumber(item)),
        datasets: [
          {
            label: "وزن رویدادها",
            backgroundColor: scoreColorSets,
            borderColor: scoreColorSets,
            borderWidth: 2,
            data: scores.map(score =>
              groupedEvents[score] ? groupedEvents[score].length : null
            )
          }
        ]
      };
    },
    scoreSum() {
      const datasets = this.days.reduce(
        (prev, day, index) => {
          const scores = this.events.reduce(
            (prev, item) =>
              item.start <= day && item.end >= day ? prev + item.score : prev,
            0
          );
          prev[0].data[index] = scores;
          return prev;
        },
        [
          {
            label: "وزن روز",
            backgroundColor: "transparent",
            borderColor: "rgb(255, 205, 86)",
            borderWidth: 3,
            data: [...this.days].fill(0)
          }
        ]
      );
      return {
        labels: this.days.map(d => persianNumber(format[0](d, "MM/dd"))),
        datasets
      };
    },
    dateRange() {
      return persianNumber(
        `${format[0](this.range[0], "yyyy/MM/dd")} - ${format[0](
          this.range[1],
          "yyyy/MM/dd"
        )}`
      );
    },
    filtersKey() {
      return Reflect.ownKeys(this.$route.query).filter(
        key => this.$route.query[key]
      );
    },
    fields() {
      return Reflect.ownKeys(model.schema)
        .sort(
          (a, b) =>
            (model.schema[a].filterOrder || Infinity) -
            (model.schema[b].filterOrder || Infinity)
        )
        .filter(
          key =>
            key !== "__ob__" &&
            !getValue(model.schema[key].hideInFilter, this.filters)
        )
        .map(key => ({
          text: this.$vuetify.lang.t(
            `$vuetify.${model.i18nPath}.${model.schema[key].name || key}`
          ),
          value: key,
          ...getValueOfObj(model.schema[key], this.filters)
        }));
    }
  },
  created() {
    this.filters = { ...this.filters, ...this.$route.query };
    this.getItems();
  },
  watch: {
    range: {
      deep: true,
      handler(newValue, oldValue) {
        if (
          this.getTime(newValue[0]) !== this.getTime(oldValue[0]) ||
          this.getTime(newValue[1]) !== this.getTime(oldValue[1])
        ) {
          this.getItems();
        }
      }
    },
    "$route.query"() {
      this.filters = { ...this.filters, ...this.$route.query };
      this.getItems();
    }
  }
};
</script>

<style lang="scss" scoped></style>
